import * as React from 'react';
import LazyLoad from 'react-lazyload';
import Grid from 'styleguide/components/Grid/Grid';
import {
  Wow,
  HeroCentered,
  StorySwift,
  StoryEpic,
  StorySpark,
  StoryVogue,
  StartSteps,
  WhyPrintivity,
} from './Dynamic.imports-loadable';
import Footer from 'styleguide/components/Footer/Footer';
import LogoGarden from 'styleguide/components/LogoGarden/LogoGarden';
import Meta from 'styleguide/components/Meta/Meta';
import { heroImg, heroImgMobile } from 'bundles/App/pages/HomePage/types';
import GoogleSearchConsole from 'bundles/App/pages/HomePage/GoogleSearchConsole';
import img from 'assets/images/business_cards_transparent_falling.png';
import imgTop from 'assets/images/business_cards_transparent_falling_top.png';
import imgBottom from 'assets/images/business_cards_transparent_falling_bottom.png';
import heroCenteredImgMobile from 'assets/images/business_cards_transparent_falling.webp';
import Hero from 'styleguide/components/Hero/Hero';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const HomePage = () => (
  <>
    <Meta
      title="Cheap Online Custom Printing Services | Printivity"
      description={
        'To grow your business with the best online printing services, visit Printivity today! ' +
        'Cheap prices, fast turnarounds, and a 100% satisfaction guarantee.'
      }
      keywords={
        'printing services, printing, online printing services, custom printing, online printing, ' +
        'printing companies, color printing, digital printing, cheap printing, printing service'
      }
      canonical="https://www.printivity.com"
      robots={['index', 'follow']}
    />

    <GoogleSearchConsole />
    <main className="mx-auto">
      <Hero
        backgroundImage={heroImg}
        backgroundImageWebp={heroImgMobile}
        buttonText="Start Now"
        buttonLink="/products"
      />
      <LogoGarden />
      <HeroCentered
        buttonText="Print Business Cards"
        buttonLink="/business-cards"
        title="Business Cards"
        subtitle="First impressions are everything - put your brand's best foot forward by choosing from a selection
          of unique Business Cards."
        img={img}
        imgTop={imgTop}
        imgBottom={imgBottom}
        backgroundImageWebp={heroCenteredImgMobile}
      />
      <LazyLoad once placeholder={LoaderWrapper('h-[805px] flex items-center justify-center')}>
        <WhyPrintivity />
      </LazyLoad>
    </main>

    <Grid.Container>
      <Grid className="py-9">
        <LazyLoad once placeholder={LoaderWrapper('h-[805px] flex items-center justify-center')}>
          <StorySwift />
        </LazyLoad>
        <LazyLoad once placeholder={LoaderWrapper('h-[805px] flex items-center justify-center')}>
          <StoryEpic />
        </LazyLoad>
        <LazyLoad once placeholder={LoaderWrapper('h-[805px] flex items-center justify-center')}>
          <StorySpark />
        </LazyLoad>
        <LazyLoad once placeholder={LoaderWrapper('h-[805px] flex items-center justify-center')}>
          <StoryVogue />
        </LazyLoad>
      </Grid>
    </Grid.Container>
    <div className="-mt-20 h-20" id="wow" />
    <LazyLoad once offset={600} placeholder={LoaderWrapper('h-[707px] flex items-center justify-center')}>
      <Wow />
    </LazyLoad>
    <LazyLoad once placeholder={LoaderWrapper('h-[1000px] flex items-center justify-center')}>
      <StartSteps />
    </LazyLoad>
    <Footer />
  </>
);

export default HomePage;

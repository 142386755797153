/* eslint-disable react/no-unknown-property */
import * as React from 'react';
import ButtonAsNativeLink from '../Button/ButtonAsNativeLink';

interface Props {
  backgroundImage?: string;
  backgroundImageWebp?: string;
  buttonText?: string;
  buttonLink?: string;
}

const Hero = ({ backgroundImage, backgroundImageWebp, buttonText, buttonLink }: Props) => (
  <div className="flex justify-center bg-blue px-4 md:h-96">
    <div
      className="flex h-full w-full max-w-base flex-col-reverse items-stretch overflow-hidden
md:flex-row md:justify-center lg:relative lg:max-w-base
lg:pl-0.5"
    >
      <div
        className="mx-auto flex h-full w-full
  flex-col justify-center py-4 text-center  md:py-0 md:text-left"
      >
        <div
          className="px-1 text-shades-0
    sm:px-2
    md:py-4 md:pl-6 md:pr-0
    lg:pl-0
    "
        >
          <h1
            className="font-hvBold text-3xl tracking-tight
      sm:text-5xl
      md:mr-4 md:text-5xl
      lg:text-6xl lg:tracking-normal
      xl:text-6xl"
          >
            <span className="block xl:inline">
              Make <br className="hidden md:inline-block" />
              Something <br className="hidden md:inline-block" />
              Real.
            </span>
          </h1>
          <p
            className="mt-3 text-base
      sm:text-xl
      md:mt-4 md:text-base"
          >
            Don&apos;t just wait for opportunities. Create them.
          </p>
          <p
            className="text-sm
       sm:text-xl
       md:text-base"
          >
            Market and promote your business with <br className="md:hidden" />
            online <br className="hidden md:inline-block xl:hidden" />
            custom printing.
          </p>
          <div className="flex justify-center md:justify-start">
            <ButtonAsNativeLink
              buttonType="link"
              className="!mt-0 rounded-md !px-14 shadow md:!py-2 md:!text-base"
              color="orange"
              target={buttonLink}
            >
              {buttonText}
            </ButtonAsNativeLink>
          </div>
        </div>
      </div>
      <div className="sm:min-h-72 relative h-64 w-full grow overflow-hidden sm:h-72 md:h-96 md:overflow-visible">
        <picture>
          <source type="image/webp" srcSet={backgroundImageWebp} />
          <img
            className="sm:scale-1 sm:translate-0 absolute inset-0
       h-full w-full -translate-y-8 scale-125
      object-contain transition"
            src={backgroundImage}
            alt="Postcards"
            fetchpriority="high"
            loading="eager"
          />
        </picture>
      </div>
    </div>
  </div>
);

export default Hero;

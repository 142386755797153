import * as React from 'react';
import { Helmet } from 'react-helmet-async';

const GoogleSearchConsole = () => (
  <Helmet>
    {/* Erik */}
    <meta name="google-site-verification" content="6FmjI4HOIQv0Pm2cXuZe8oKVgNbddeki0LtGzaIhINg" />
    {/* Google Shopping */}
    <meta name="google-site-verification" content="WOC8CuAbOGuJHar12-lYhJH-jCOOUYf03I6_1XNdNPg" />
  </Helmet>
);

export default GoogleSearchConsole;

import {
  IconHappyCustomer,
  IconPriceMatch,
  IconSatisfactionGuarantee,
  IconFreeResources,
  IconGraphicDesign,
} from 'styleguide/icons';
import { Info } from 'styleguide/components/InfoCards/InfoCard/types';

const iconPaths = [
  IconHappyCustomer,
  IconPriceMatch,
  IconSatisfactionGuarantee,
  IconFreeResources,
  IconGraphicDesign,
];

const titles = [
  '60,000+ Happy Customers',
  'Price Match',
  'Satisfaction Guarantee',
  'Free Resources',
  'Graphic Design',
];

const contents = [
  'Join the thousands of customers who choose to grow their business with us. ',
  'Found a better price? Send us your quote and we’ll meet or beat. ',
  'Not satisfied? We’ll fix it, replace it, or refund it.',
  "Subscribe to our blog, <a href='/insights' style='color: #323232; text-decoration:none;'>Printivity Insights</a>, to learn about print products, design, and marketing.",
  'Designing for print is our expertise. Whether you need to create a new design or update an existing one, we’ve got you covered!',
];

export const infoCards: Info[] = [
  { title: titles[0], icon: iconPaths[0], content: contents[0] },
  { title: titles[1], icon: iconPaths[1], content: contents[1] },
  { title: titles[2], icon: iconPaths[2], content: contents[2] },
  { title: titles[3], icon: iconPaths[3], content: contents[3] },
  { title: titles[4], icon: iconPaths[4], content: contents[4] },
];

export { default as heroImg } from 'assets/images/Postcards_Transparent.png';
export { default as heroImgMobile } from 'assets/images/Postcards_Transparent.webp';

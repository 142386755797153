import * as React from 'react';

import logo1 from 'assets/images/logo_garden/avery_dennison.png';
import logo2 from 'assets/images/logo_garden/berkshire_hathaway.png';
import logo3 from 'assets/images/logo_garden/jansport.png';
import logo5 from 'assets/images/logo_garden/bmw.png';
import logo6 from 'assets/images/logo_garden/fico.png';
import logo7 from 'assets/images/logo_garden/orange_theory_fitness.png';

const LogoGarden = () => (
  <div className="border-y border-gray-200 bg-[#f8f8f8]">
    <div className="mx-auto grid max-w-base auto-rows-auto grid-cols-2 place-content-center overflow-hidden px-4 md:grid-cols-3 lg:grid lg:grid-cols-6">
      <img
        className="mx-auto h-16 md:scale-125 lg:h-24"
        title="Avery Dennison"
        alt="Avery Dennison Logo"
        src={logo1}
      />
      <img
        className="mx-auto h-16 md:scale-125 lg:h-24"
        title="Berkshire Hathaway"
        alt="Berkshire Hathaway Logo"
        src={logo2}
      />
      <img className="mx-auto h-16 md:scale-125 lg:h-24" title="Jansport" alt="Jansport Logo" src={logo3} />
      <img className="mx-auto h-16 md:scale-125 lg:h-24" title="BMW" alt="BMW Logo" src={logo5} />
      <img className="mx-auto h-16 md:scale-125 lg:h-24" title="FICO" alt="FICO Logo" src={logo6} />
      <img
        className="mx-auto h-16 md:scale-125 lg:h-24"
        title="Orange Theory Fitness"
        alt="Orange Theory Fitness Logo"
        src={logo7}
      />
    </div>
  </div>
);

export default LogoGarden;

import loadable from '@loadable/component';
import retry from 'utils/retry';

export const HeroCentered = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './HeroCentered/HeroCentered')),
);
export const Wow = loadable(() => retry(() => import(/* webpackPrefetch: true */ './Wow/Wow')));
export const StorySwift = loadable(() => retry(() => import(/* webpackPrefetch: true */ './StorySwift')));
export const StoryEpic = loadable(() => retry(() => import(/* webpackPrefetch: true */ './StoryEpic')));
export const StorySpark = loadable(() => retry(() => import(/* webpackPrefetch: true */ './StorySpark')));
export const StoryVogue = loadable(() => retry(() => import(/* webpackPrefetch: true */ './StoryVogue')));
export const StartSteps = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './StartSteps/StartSteps')),
);
export const WhyPrintivity = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './WhyPrintivity/WhyPrintivity')),
);
